// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import axios from 'axios';
import axiosRetry from 'axios-retry';
import Rails from '@rails/ujs';
axios.defaults.timeout = 60000;
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').content;

axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 2000;
    },
    retryCondition: (error) => {
        return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response?.status >= 500;
    },
});

global.$ = require('jquery')
require("@rails/activestorage").start()

// Application code
require("../shared/modals")
require("../app/audio_polyfill")
require("../app/picture_exercise")
require("../app/pronunciation_exercise")
require("../app/word_to_picture_exercise")
require("../app/script_training_exercise")
require("../app/instructional_videos")
require("../app/audio_resources")
require("../app/simple_bar")
require("../app/calendar")
require("../app/simple_calendar")
require("../app/pie_chart")
require("../app/local_time")
require("../app/modal_trigger")
require("../shared/ai_video_preview")
require("../app/ema_modal_buttons")
require("../app/image_preview_modal_trigger")
require("../participant/word_list_upload")
require("../app/detailed_rollbar_log")

import "../stylesheets/app.scss"
import '../vendor/star-rating.js'
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
import '../vendor/bootstrap.js'
require.context('../images', true)
// const imagePath = (name) => images(name, true)

Rails.start();
